import router from './router'
import store from './store'
import { Message } from '@um/element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import { getToken } from '@/utils/auth' // get token from cookie
import autoLogin from '@/utils/autoLogin'
import getPageTitle from '@/utils/get-page-title'
import { getListDict } from '@/utils'

import imSdk from '@um/im'
import { log } from 'mathjs'
import Vue from 'vue'

NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/auth-redirect', '/jump', '/redirectLink'] // no redirect whitelist

router.beforeEach(async(to, from, next) => {
  // start progress bar
  NProgress.start()
  // set page title
  document.title = getPageTitle(to.meta.title)
  // determine whether the user has logged in
  let userToken = getToken()
  const hasRoles = store.getters.roles && store.getters.roles.length > 0
  if (navigator.userAgent.toLowerCase().match(/Mobi|Android|iPhone/i)) {
    // 当前设备是移动设备
    const url = window.location.origin + to.fullPath
    location.href = url.replace('yky.youmatech.com', 'ykyapp.youmatech.com')
    return next(false)
  }
  // 官网展示的逻辑，走自动登录
  if (process.env.VUE_APP_NAME === 'yky_website') {
    if (!userToken) {
      // 处理逻辑
      userToken = await autoLogin()
    }
  }
  // 官网逻辑结束
  try {
    if (userToken) {
      let accelerated, enterpriseUuid, enterpriseDefaultFlag
      if (!hasRoles) { // 有权限代表此时已经进入系统中，则无需判断个人信息状态、企业状态，不请求接口减少页面跳转时间
        const { accelerated: acc, enterpriseUuid: id, enterpriseDefaultFlag: enter } = await store.dispatch('user/getStatus')
        accelerated = acc
        enterpriseUuid = id
        enterpriseDefaultFlag = enter
      }

      // debugger
      if (!hasRoles && !enterpriseDefaultFlag && (!accelerated || !enterpriseUuid)) {
        // 此处做判断，如用户未完善个人信息、未选择企业则需跳转至登录页引导
        if (to.path.includes('/login') && to.params.status) {
          next()
          NProgress.done()
        } else {
          let param
          if (!accelerated) param = 'register'
          else if (!enterpriseUuid) param = 'choseEnterprise'
          next({
            name: 'Login',
            params: {
              status: param
            }
          })
          NProgress.done()
        }
      } else {
        if (to.path === '/login') {
          // if is logged in, redirect to the home page
          next({ path: '/' })
          NProgress.done() // hack: https://github.com/PanJiaChen/vue-element-admin/pull/2939
        } else {
          // determine whether the user has obtained his permission roles through getInfo
          if (hasRoles) {
            next()
          } else {
            try {
              // get user info
              // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
              const { userUuid, userMobile, nickName, roles, imAccountId, imAccountToken } = await store.dispatch('user/getInfo')
              // 神策数据
              try {
                window.sensors.login(userUuid)
                window.sensors.bind('$identity_mobile', userMobile)
                window.sensors.setProfile({ userUuid: userUuid })
                window.sensors.setProfile({ nickName: nickName })
                window.sensors.setProfile({ userMobile: userMobile })
              } catch (e) {

              }
              // generate accessible routes map based on roles
              const accessRoutes = await store.dispatch('permission/generateRoutes', roles)

              // dynamically add accessible routes
              router.addRoutes(accessRoutes)

              await getListDict() // 顺便处理下全局字典表

              if (process.env.VUE_APP_NAME !== 'yky_website') {
                // 初始化im
                console.log('permission-im-init')
                imSdk.init(imAccountId, imAccountToken, userToken)
              }
              // hack method to ensure that addRoutes is complete
              // set the replace: true, so the navigation will not leave a history record
              next({ ...to, replace: true })
            } catch (error) {
              console.log('permission-err', error)
              // remove token and go to login page to re-login
              Message.error(error || 'Has Error')
              from.path != '/login' && store.dispatch('user/resetToken').then(_ => {
                next('/login')
              })
              NProgress.done()
            }
          }
        }
      }
    } else {
      /* has no token */
      if (whiteList.indexOf(to.path) !== -1) {
        // in the free login whitelist, go directly
        next()
      } else {
        // other pages that do not have permission to access are redirected to the login page.
        next(`/login?redirect=${to.path}`)
        NProgress.done()
      }
    }
  } catch (e) {
    console.error('promise', e, to, from)
    from.path != '/login' && store.dispatch('user/resetToken').then(_ => {
      next('/login')
    })
  }
})

router.afterEach((to, from) => {
  // 处理神策的公共数据
  try {
    window.sensors.registerPage({
      $application_name: '优客云运营端-平台', // 应用名称
      $module_name: to.meta?.title || '', // 模块名称
      $page_name: '' // 页面名称
    })
    Vue.nextTick(() => {
      window.sensors.quick('autoTrackSinglePage')
    })
  } catch (e) {

  }
  // finish progress bar
  NProgress.done()
})
