import Vue from 'vue'
import 'normalize.css/normalize.css' // a modern alternative to CSS resets
import Element from '@um/element-ui'
import './styles/element-variables.scss'
import '@/styles/index.scss' // global css
import '@um/location/dist/location.css'
import '@um/upload-img/dist/upload-img.css'
import '@um/upload/dist/upload.css'
import '@um/im/dist/im.css'
import '@um/edit/dist/edit.css'
import '../node_modules/handsontable/dist/handsontable.full.css'

import qifuyun from 'qifuyun-pc' // 开发模式走本地依赖文件，需先本地下载 http://git.pm.youmatech.com/enterprise-service-cloud/front/pc_components，并npm i该项目
import umIM from '@um/im'
import { getToken } from '@/utils/auth'
import UmLocation from '@um/location'
import UmUpload from '@um/upload'
import UmUploadImg from '@um/upload-img'
import UmEdit from '@um/edit'

import draggable from 'vuedraggable'

import {
  getOrganUserTree, getOrganUserInfo, getRootOrganInfo,
  getRoleTree, getListRoleByUuid,
  getFlowInfo, getModelInfo, saveModelInfo, getSysTemTemInfo,
  operatorFlowConfigSystemGetGetOne, flowConfigSystemAddUpdateSaveTemplate
} from '@/api/flow'
// 审批流组件
import umFlow from '@um/flow'

// 本地调试的时候注释
import '@um/flow/lib/flow.css'

import App from './App'
import store from './store'
import router from './router'

import './icons' // icon
import './permission' // permission control

import * as filters from './filters' // global filters
import components from './components'

import clipboard from '@/directive/clipboard'
// 错误处理
import { errorHandle } from '@/utils'

import permission from '@/directive/permission'

// 微前端
import './sdk'
import microApp from '@micro-zoe/micro-app'
const logo = require('./assets/images/logo.png')
microApp.start({
  /**
   * 自定义fetch
   * @param {string} url 静态资源地址
   * @param {object} options fetch请求配置项
   * @param {string|null} appName 应用名称
   * @returns Promise<string>
   */
  fetch(url, options, appName) {
    const config = {
      // fetch 默认不带cookie，如果需要添加cookie需要配置credentials
      // credentials: 'include' // 请求时带上cookie
    }
    return window.fetch(url, Object.assign(options, config)).then((res) => {
      return res.text()
    })
  },
  excludeAssetFilter(assetUrl) {
    if (assetUrl.includes('api.map.baidu.com')) {
      return true // 返回true则micro-app不会劫持处理当前文件
    }
    return false
  },
  customProxyDocumentProperties: new Map([
    ['title', (value) => {}]
  ]),
  plugins: {
    // global: [{
    //   escapeProperties: ['areaList', 'unionMccList', 'suportBanks']
    // }]
    modules: {
      AppPkg41: [{
        loader(code, url) {
          if (url === 'https://cloudpnrcdn.oss-cn-shanghai.aliyuncs.com/opps/ui/data/areaList.js') {
            code = code.replace('var areaList', 'window.areaList')
          }
          if (url === 'https://cloudpnrcdn.oss-cn-shanghai.aliyuncs.com/opps/ui/data/unionMccList.js') {
            code = code.replace('var unionMccList', 'window.unionMccList')
          }
          if (url === 'https://cloudpnrcdn.oss-cn-shanghai.aliyuncs.com/opps/ui/data/banks.js') {
            code = code.replace('var suportBanks', 'window.suportBanks')
          }
          return code
        }
      }],
      AppPkg34: [{
        loader(code, url) {
          if (url === 'https://cloudpnrcdn.oss-cn-shanghai.aliyuncs.com/opps/ui/data/areaList.js') {
            code = code.replace('var areaList', 'window.areaList')
          }
          if (url === 'https://cloudpnrcdn.oss-cn-shanghai.aliyuncs.com/opps/ui/data/unionMccList.js') {
            code = code.replace('var unionMccList', 'window.unionMccList')
          }
          if (url === 'https://cloudpnrcdn.oss-cn-shanghai.aliyuncs.com/opps/ui/data/banks.js') {
            code = code.replace('var suportBanks', 'window.suportBanks')
          }
          return code
        }
      }]
    }
  }
})

Vue.use(permission) // 自定义指令
Vue.use(components)
Vue.use(clipboard)
Vue.use(draggable)

Vue.prototype.$errorHandle = errorHandle

Vue.use(UmEdit, {
  uploadUrl: process.env.VUE_APP_BASE_API.replace(/\/$/, '') + '/api/v1/file_manage/file/upload',
  baseURL: process.env.VUE_APP_BASE_API,
  getToken: getToken
})
Vue.prototype.$load = () => {
  return Vue.prototype.$loading({
    lock: true,
    customClass: 'customLoading',
    background: 'rgba(0, 0, 0, 0.5)'
  })
}
Vue.prototype.$payLoad = () => {
  return Vue.prototype.$loading({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    customClass: 'customPayLoad',
    background: 'rgba(255, 255, 255, 0.5)'
  })
}
Vue.use(Element)
Vue.use(UmLocation)
Vue.use(UmUpload, {
  bigUploadUrl: process.env.VUE_APP_BASE_API.replace(/\/$/, '') + '/api/v1/file_manage/file/upload',
  uploadUrl: process.env.VUE_APP_BASE_API.replace(/\/$/, '') + '/api/v1/file_manage/file/upload',
  getToken() {
    return getToken()
  },
  httpCallHandler(res, type) {
    console.log(res)
    if (type === 'upload') {
      return {
        name: res.fileName,
        fileExtension: res.fileType,
        src: res.fileFullPath
      }
    } else {
      return {
        name: res.fileName,
        url: res.fileFullPath,
        filePath: res.filePath,
        fileExtension: res.fileType
      }
    }
  }
})
Vue.use(UmUploadImg, {
  uploadUrl: process.env.VUE_APP_BASE_API.replace(/\/$/, '') + '/api/v1/file_manage/file/upload',
  getToken: getToken,
  // 文件上传成功回调
  httpCallHandler: data => {
    return {
      name: data.fileName,
      src: data.fileFullPath
    }
  }
})

Vue.use(umFlow, {
  api: {
    getOrganUserTree, // 获取组织项目人员树，平级结构
    getOrganUserInfo, // 查询组织人员信息
    getRootOrganInfo, // 查询组织根信息（用来做全部公司的请求）
    getRoleTree,
    getListRoleByUuid,
    // 审批流相关
    getFlowInfo: getFlowInfo, // 获取系统默认字段
    getSysTemTemInfo: getSysTemTemInfo, // 获取模版详情
    getModelInfo, // 获取流程模型详情
    saveModelInfo, // 保存流程模型
    operatorFlowConfigSystemGetGetOne, // 运营端模版配置，获取流程信息
    flowConfigSystemAddUpdateSaveTemplate // 运营端模版保存接口
  },
  getToken: getToken
})

// 自定义组件
// 设置请求地址
// qifuyun.setting.baseURL = 'http://api.pm.youmatech.com/mock/833/'
// qifuyun.setting.baseURL = '/'
qifuyun.setting.baseURL = process.env.VUE_APP_BASE_API
// 设置 token 值
qifuyun.setting.tokenKey = 'qifuyun-pc-token'
Vue.use(qifuyun)

Vue.use(umIM, {
  baseURL: process.env.VUE_APP_BASE_API,
  logo: logo
})
// register global utility filters
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})

Vue.prototype.$formatterTable = (row, column, cellValue, index) => {
  if (row === null || row === undefined || row === '') {
    return '--'
  }
  if (typeof row === 'string' || typeof row === 'number') {
    return row
  }
  if (cellValue === '' || cellValue === null || cellValue === undefined) {
    return '--'
  } else {
    return cellValue
  }
}
// 环境变量
Vue.prototype.$isWebsite = process.env.VUE_APP_NAME === 'yky_website'
Vue.config.productionTip = false
// eslint-disable-next-line no-new
new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
})
