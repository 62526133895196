import Layout from '@/layout'
import { sameList } from '@/utils'
import store from '@/store'

const applicationRouter = {
  path: '/application',
  component: Layout,
  name: 'application',
  // redirect: ' /index',
  // redirect: to => {
  //   for (let i = 0; i < applicationRouter.children.length; i++) {
  //     const { path, meta } = applicationRouter.children[i]
  //     if (sameList(store.getters.roles, meta.roles) && meta.level === 2) return path
  //   }
  // },
  meta: { level: 1 },
  children: [
    {
      path: '/application/zky',
      name: 'ApplicationZky',
      component: () => import('@/views/application/zky'),
      meta: { title: '智客云', cache: false, group: 'zky', level: 3, activeMenu: '/dashboard' }
    },
    {
      path: '/application/mini',
      name: 'AppMini',
      component: () => import('@/views/application/mini'),
      meta: { title: '优享家', cache: false, group: 'AppMini', level: 3, activeMenu: '/dashboard' }
    },
    {
      path: '/application/sale',
      name: 'AppSale',
      component: () => import('@/views/application/sale'),
      meta: { title: '在线售楼处', cache: false, group: 'AppSale', level: 3, activeMenu: '/dashboard' }
    },
    {
      path: '/application/comm',
      name: 'AppComm',
      component: () => import('@/views/application/comm'),
      meta: { title: '公共主数据', cache: false, group: 'AppComm', level: 3, activeMenu: '/dashboard' }
    },
    { path: '/application/pkg1', name: 'AppPkg1', component: () => import('@/views/application/pkg1'), meta: { title: '报事报修', cache: false, group: 'AppPkg1', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg2', name: 'AppPkg2', component: () => import('@/views/application/pkg2'), meta: { title: '物业缴费', cache: false, group: 'AppPkg2', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg3', name: 'AppPkg3', component: () => import('@/views/application/pkg3'), meta: { title: '优交付', cache: false, group: 'AppPkg3', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg4', name: 'AppPkg4', component: () => import('@/views/application/pkg4'), meta: { title: '设备巡检', cache: false, group: 'AppPkg4', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg5', name: 'AppPkg5', component: () => import('@/views/application/pkg5'), meta: { title: '合同管理', cache: false, group: 'AppPkg5', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg6', name: 'AppPkg6', component: () => import('@/views/application/pkg6'), meta: { title: '在线报修', cache: false, group: 'AppPkg6', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg7', name: 'AppPkg7', component: () => import('@/views/application/pkg7'), meta: { title: '在线缴费', cache: false, group: 'AppPkg7', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg8', name: 'AppPkg8', component: () => import('@/views/application/pkg8'), meta: { title: '在线交付', cache: false, group: 'AppPkg8', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg9', name: 'AppPkg9', component: () => import('@/views/application/pkg9'), meta: { title: '业主认证', cache: false, group: 'AppPkg9', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg10', name: 'AppPkg10', component: () => import('@/views/application/pkg10'), meta: { title: '库存管理', cache: false, group: 'AppPkg10', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg11', name: 'AppPkg11', component: () => import('@/views/application/pkg11'), meta: { title: '综合巡逻', cache: false, group: 'AppPkg11', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg12', name: 'AppPkg12', component: () => import('@/views/application/pkg12'), meta: { title: '品质核查', cache: false, group: 'AppPkg12', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg13', name: 'AppPkg13', component: () => import('@/views/application/pkg13'), meta: { title: '能源管理', cache: false, group: 'AppPkg13', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg14', name: 'AppPkg14', component: () => import('@/views/application/pkg14'), meta: { title: '风控管理', cache: false, group: 'AppPkg14', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg15', name: 'AppPkg15', component: () => import('@/views/application/pkg15'), meta: { title: '装修管理', cache: false, group: 'AppPkg15', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg16', name: 'AppPkg16', component: () => import('@/views/application/pkg16'), meta: { title: '费项支出', cache: false, group: 'AppPkg16', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg17', name: 'AppPkg17', component: () => import('@/views/application/pkg17'), meta: { title: '访客邀请', cache: false, group: 'AppPkg17', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg18', name: 'AppPkg18', component: () => import('@/views/application/pkg18'), meta: { title: '家书管理', cache: false, group: 'AppPkg18', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg19', name: 'AppPkg19', component: () => import('@/views/application/pkg19'), meta: { title: '产证查询', cache: false, group: 'AppPkg19', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg20', name: 'AppPkg20', component: () => import('@/views/application/pkg20'), meta: { title: '公告通知', cache: false, group: 'AppPkg20', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg21', name: 'AppPkg21', component: () => import('@/views/application/pkg21'), meta: { title: '运营活动', cache: false, group: 'AppPkg21', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg22', name: 'AppPkg22', component: () => import('@/views/application/pkg22'), meta: { title: '便民信息', cache: false, group: 'AppPkg22', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg23', name: 'AppPkg23', component: () => import('@/views/application/pkg23'), meta: { title: '楼宇管家', cache: false, group: 'AppPkg23', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg24', name: 'AppPkg24', component: () => import('@/views/application/pkg24'), meta: { title: '问卷调查', cache: false, group: 'AppPkg24', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg25', name: 'AppPkg25', component: () => import('@/views/application/pkg25'), meta: { title: '房屋租售', cache: false, group: 'AppPkg25', level: 3, activeMenu: '/dashboard' }},

    { path: '/application/pkg27', name: 'AppPkg27', component: () => import('@/views/application/pkg27'), meta: { title: '客户管理', cache: false, group: 'AppPkg27', level: 3, activeMenu: '/dashboard' }},

    { path: '/application/pkg28', name: 'AppPkg28', component: () => import('@/views/application/pkg28'), meta: { title: '营销团队', cache: false, group: 'AppPkg28', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg29', name: 'AppPkg29', component: () => import('@/views/application/pkg29'), meta: { title: '优案场', cache: false, group: 'AppPkg29', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg30', name: 'AppPkg30', component: () => import('@/views/application/pkg30'), meta: { title: '优行销', cache: false, group: 'AppPkg30', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg31', name: 'AppPkg31', component: () => import('@/views/application/pkg31'), meta: { title: '优渠道', cache: false, group: 'AppPkg31', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg32', name: 'AppPkg32', component: () => import('@/views/application/pkg32'), meta: { title: '优接待', cache: false, group: 'AppPkg32', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg33', name: 'AppPkg33', component: () => import('@/views/application/pkg33'), meta: { title: '会员中心', cache: false, group: 'AppPkg33', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg34', name: 'AppPkg34', component: () => import('@/views/application/pkg34'), meta: { title: '在线商城', cache: false, group: 'AppPkg34', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg35', name: 'AppPkg35', component: () => import('@/views/application/pkg35'), meta: { title: '营销中心', cache: false, group: 'AppPkg35', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg36', name: 'AppPkg36', component: () => import('@/views/application/pkg36'), meta: { title: '潜客中心', cache: false, group: 'AppPkg36', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg37', name: 'AppPkg37', component: () => import('@/views/application/pkg37'), meta: { title: '邻里圈', cache: false, group: 'AppPkg37', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg38', name: 'AppPkg38', component: () => import('@/views/application/pkg38'), meta: { title: '客户关怀', cache: false, group: 'AppPkg38', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg39', name: 'AppPkg39', component: () => import('@/views/application/pkg39'), meta: { title: '满意度', cache: false, group: 'AppPkg39', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg40', name: 'AppPkg40', component: () => import('@/views/application/pkg40'), meta: { title: '物业大屏', cache: false, group: 'AppPkg40', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg41', name: 'AppPkg41', component: () => import('@/views/application/pkg41'), meta: { title: '支付分账', cache: false, group: 'AppPkg41', level: 3, activeMenu: '/dashboard' }},
    { path: '/application/pkg49', name: 'AppPkg49', component: () => import('@/views/application/pkg49'), meta: { title: '行为埋点', cache: false, group: 'AppPkg49', level: 3, activeMenu: '/dashboard' }}
  ]
}
export default applicationRouter
